// Mobile Navigation JS
import '../../navigation';

import { DOMRouteHandler } from '../../util/Router';
import { healthConditionsSearchInit } from './initHealthConditionsSearch';
import { initLegacyLazyloadImageHandling } from '../../modules/media/LegacyLazyloadImageHandling';
import { initSnapScrollNavigation } from '../../modules/snap-scroll-navigation';
import { makeLiveChatUpdates } from '../../modules/live-chat';
import { notifyHandledException } from '../../util/error-handling';
import { setup as setupFoundation } from '../../modules/foundation';
import { setupHomePageSwiperSlider } from './setupHomepageSwiperSliders';
import { setupScrollReveal } from '../../modules/scroll-reveal';
import { shortcodeBreedHealth } from '../../modules/shortcode-breed-health';
import { skipLinkFocus } from '../../modules/skip-link-focus';

export class Common extends DOMRouteHandler {
  /**
   * Called on ever page before finalize
   */
  static init(): void {
    // anything that's common between all pages goes here
    setupFoundation();

    // Confidence Banner

    if (document.querySelector('.confidence-banner-slider')) {
      import('../../modules/confidence-banner')
        .then(({ initConfidenceBarNavigation }) => {
          initConfidenceBarNavigation();
        })
        .catch((e) => notifyHandledException(e));
    }

    // Scroll Reveal
    setupScrollReveal();

    // skip link focus fix
    skipLinkFocus();
    initLegacyLazyloadImageHandling();

    // Snap scroll navigation
    initSnapScrollNavigation(document.querySelectorAll('[data-controls]'));

    // Holiday live chat updates
    makeLiveChatUpdates();

    if (document.querySelector('[data-accordion-for]')) {
      import('../../modules/accordion')
        .then(({ initResponsiveAccordions }) => {
          initResponsiveAccordions();
        })
        .catch((e) => {
          notifyHandledException(e);
        });
    }

    if (document.querySelector('.interactive-chromies-block')) {
      import('../../modules/interactiveBlock')
        .then(({ initInteractiveBlock }) => {
          initInteractiveBlock();
        })
        .catch((e) => {
          notifyHandledException(e);
        });
    }

    setupHomePageSwiperSlider();

    if (document.querySelector('[data-play-button]')) {
      import('../../modules/lazyloaded-inline-video-embed')
        .then(({ initInlineVideoIframe }) => {
          initInlineVideoIframe();
        })
        .catch(notifyHandledException);
    }

    if (document.querySelector('[data-drawer-trigger]')) {
      import('../../modules/drawers')
        .then(({ initDrawers }) => {
          initDrawers();
        })
        .catch(notifyHandledException);
    }
  }

  /**
   * Called on every page after init
   */
  static finalize(): void {
    shortcodeBreedHealth();

    healthConditionsSearchInit().catch((e) => {
      notifyHandledException(e);
    });

    const accordionContainer = document.getElementById('deeplinked-accordion');

    if (accordionContainer) {
      import('../../modules/compare-products')
        .then(({ setUpToggleAllRowsHandler }) => {
          setUpToggleAllRowsHandler(accordionContainer);
        })
        .catch((e) => {
          notifyHandledException(e);
        });
    }

    if (document.querySelector('#jazzHRJobs')) {
      import('../../modules/jazzHRFilters')
        .then(({ initCareersSearchFilters }) => {
          initCareersSearchFilters();
        })
        .catch((e) => {
          notifyHandledException(e);
        });
    }

    /*
    When the document has finished loading, we add the 'document-ready' class
    to the body. This prevents any CSS animations from trying to start too early,
    and juddering while the DOM is still rendering
    */
    setTimeout(function () {
      jQuery('body').addClass('document-ready');

      const accordionItems = document.querySelectorAll<HTMLElement>('section [data-tab-content]');
      if (accordionItems) {
        import('../../modules/accordion')
          .then(({ removeAriaInContent }) => {
            removeAriaInContent(accordionItems);
          })
          .catch((e) => {
            notifyHandledException(e);
          });
      }
    }, 500);
  }
}
